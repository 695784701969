@font-face {
  font-family: 'icomoon';
  src:
    url('fonts/icomoon.woff2') format('woff2'),
    url('fonts/icomoon.ttf') format('truetype'),
    url('fonts/icomoon.woff') format('woff'),
    url('fonts/icomoon.svg') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-filters:before {
  content: "\e92b";
}
.icon-search:before {
  content: "\e92a";
}
.icon-menu:before {
  content: "\e929";
}
.icon-trash:before {
  content: "\e92c";
}
.icon-pen:before {
  content: "\e930";
}
.icon-plus:before {
  content: "\e926";
}
.icon-all-categories-bold:before {
  content: "\e924";
}
.icon-case-studies-bold:before {
  content: "\e925";
}
.icon-diagnostics-biomarkers-bold:before {
  content: "\e927";
}
.icon-disease-overview-bold:before {
  content: "\e928";
}
.icon-therapeutic-agents-bold:before {
  content: "\e92d";
}
.icon-treatment-landscape-bold:before {
  content: "\e92e";
}
.icon-other-videos-bold:before {
  content: "\e92f";
}
.icon-treatment-landscape:before {
  content: "\e920";
  color: #484f50;
}
.icon-therapeutic-agents:before {
  content: "\e921";
  color: #484f50;
}
.icon-all-categories:before {
  content: "\e91c";
}
.icon-case-studies:before {
  content: "\e91d";
  color: #484f50;
}
.icon-diagnostics-biomarkers:before {
  content: "\e91e";
  color: #484f50;
}
.icon-disease-overview:before {
  content: "\e91f";
  color: #484f50;
}
.icon-arrow-right:before {
  content: "\e91b";
}
.icon-play-circle:before {
  content: "\e91a";
}
.icon-chapters-toggle:before {
  content: "\e919";
}
.icon-chevron-right:before {
  content: "\e918";
}
.icon-chevron-left:before {
  content: "\e917";
}
.icon-clock:before {
  content: "\e914";
}
.icon-external-link:before {
  content: "\e915";
}
.icon-user:before {
  content: "\e916";
}
.icon-chevron-fill-up:before {
  content: "\e910";
}
.icon-chevron-fill-right:before {
  content: "\e911";
}
.icon-chevron-fill-left:before {
  content: "\e912";
}
.icon-chevron-fill-down:before {
  content: "\e913";
}
.icon-share-apple:before {
  content: "\e90f";
}
.icon-thumbs-up:before {
  content: "\e90c";
}
.icon-share:before {
  content: "\e90d";
}
.icon-bookmark:before {
  content: "\e90e";
}
.icon-check:before {
  content: "\e90b";
}
.icon-x:before {
  content: "\e90a";
}
.icon-play-backward:before {
  content: "\e908";
}
.icon-play-forward:before {
  content: "\e909";
}
.icon-pause:before {
  content: "\e907";
}
.icon-play:before {
  content: "\e904";
}
.icon-seek-backward:before {
  content: "\e905";
}
.icon-seek-forward:before {
  content: "\e906";
}
.icon-blue-star:before {
  content: "\e903";
  color: #08aeea;
}
.icon-eye-off:before {
  content: "\e901";
}
.icon-eye-on:before {
  content: "\e900";
}
.icon-chevron-down:before {
  content: "\e902";
}
.icon-disease:before {
  content: "\e923";
}
.icon-arrow-down:before {
  content: "\e922";
}
.icon-volume-mute2:before {
  content: "\ea2a";
}
