
                                @import '~/home/pymedi/app/pymedi/static/scss/includes/_i_mixins';
                                @import '~/home/pymedi/app/pymedi/static/scss/includes/_i_variables';
                            
@import "~Styles/root.scss";
@import "../icons/icomoon/style.css";

@import "~Styles/components/horizontal_tabs";
@import "~Styles/components/cards.scss";
@import "~Styles/components/radiobox.scss";
@import "~Styles/components/checkbox.scss";
@import "~Styles/components/header.scss";
@import "~Styles/components/footer.scss";
@import "~Styles/components/buttons.scss";
@import "~Styles/components/labels.scss";
@import "~Styles/components/profile_image.scss";

.topics-page {
  .filters {
    width: 100%;
    padding-top: 24px;

    &__container {
      display: flex;
      flex-direction: column;
    }

    &__page_title {
      font-family: "Open Sans";
      font-size: 20px;
      font-weight: 600;
      line-height: 26px;
      letter-spacing: -0.2px;
      color: #2c2c2e;
    }

    &__title {
      margin-bottom: 20px;
      font-family: "Open Sans", sans-serif;
      font-size: 20px;
      font-weight: 300;
      color: #000;
    }

    &__list {
      width: 100%;
      display: flex;
      flex-direction: column;
    }

    &__item {
      width: 100%;
      font-family: "Open Sans", sans-serif;
      font-size: 14px;
      font-weight: 600;
      margin-top: 0.8em;
      margin-bottom: 0.8em;
      letter-spacing: 0.1px;
      text-align: left;
      color: #000;

      &:hover {
        text-decoration: none;
      }

      &.active {
        color: #1ec8d1;
      }
    }
  }

  .news-feed__title {
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;
    font-family: "Open Sans", sans-serif;
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 0.1px;
    color: #000;
  }

  .sort-by {
    display: flex;
    align-items: center;
    font-family: "Lato", sans-serif;
    font-size: 15px;
    font-weight: bold;
    letter-spacing: 1px;
    text-align: left;
    color: #8e90a0;
    text-transform: uppercase;

    .form-control {
      margin-left: 16px;
      width: unset;
    }
  }

  .load-more {
    display: block;
    margin: 0 auto;
    margin-bottom: 32px;
  }

  &.lecture-series-list-page {
    .filters {
      @include min-screen(992px) {
        padding-top: 23px;
      }

      &--sticky {
        @include min-screen(992px) {
          position: sticky;
          top: 0;
        }
      }

      &__page_title {
        margin: 0;
      }

      &__list {
        row-gap: 12px;

        @include min-screen(992px) {
          height: calc(100vh - var(--filter-list-top-offset) + var(--page-scroll-amount));
          max-height: calc(100vh - var(--filter-list-top-offset) - 20px);
          padding: 0 5px 0 0;
          overflow: auto;

          &::-webkit-scrollbar {
            width: 4px;
            height: 6px;
          }

          &::-webkit-scrollbar-track {
            background: rgba(#c7c7cc,0.3);
          }

          &::-webkit-scrollbar-thumb {
            border-radius: 10px;
            background: #c7c7cc;
          }

          &::-webkit-scrollbar-thumb:hover {
            background: #c7c7cc;
          }

          &::-webkit-scrollbar-thumb:active {
            background: #c7c7cc;
          }
        }
      }

      &__item {
        width: 100%;
        display: flex;
        justify-content: space-between;
        font-family: "Open Sans", sans-serif;
        font-size: 14px;
        line-height: 20px;
        font-weight: 600;
        margin: 0;
        letter-spacing: 0.1px;
        text-align: left;
        color: #000;

        &:hover {
          text-decoration: none;
        }
      }
    }
  }

  &.browse-term-page {
    .search-term {
      @include min-screen(992px) {
        display: none;
      }
    }

    .filters {

      &--sticky {
        @include min-screen(992px) {
          position: sticky;
          top: 23px;
          padding: 0;
        }
      }

      &__title {
        margin: 0;
        font-size: 12px;
        line-height: 16px;
        font-weight: 400;
        letter-spacing: 0.12px;
      }

      &__divider {
        border-top: 1px solid #E8E8E8;
        margin: 16px 0;
      }

      &__inner_wrap {
        @include min-screen(992px) {
          height: calc(100vh - var(--filter-list-top-offset) + var(--page-scroll-amount));
          max-height: calc(100vh - var(--filter-list-top-offset) - 20px);
          overflow: auto;

          &::-webkit-scrollbar {
            width: 4px;
            height: 6px;
          }

          &::-webkit-scrollbar-track {
            background: rgba(#c7c7cc,0.3);
          }

          &::-webkit-scrollbar-thumb {
            border-radius: 10px;
            background: #c7c7cc;
          }

          &::-webkit-scrollbar-thumb:hover {
            background: #c7c7cc;
          }

          &::-webkit-scrollbar-thumb:active {
            background: #c7c7cc;
          }
        }
      }

      &__list {
        row-gap: 12px;

        @include min-screen(992px) {
          padding: 0 5px 0 0;
        }
      }

      &__item {
        width: 100%;
        display: flex;
        justify-content: space-between;
        font-family: "Open Sans", sans-serif;
        font-size: 14px;
        line-height: 20px;
        font-weight: 600;
        margin: 0;
        letter-spacing: 0.1px;
        text-align: left;
        color: #000;

        &:hover {
          text-decoration: none;
        }
      }
    }
  }
}

.video-page {

  .navbar {
    @include screen(576px, 991px) {
      padding: 8px 24px;
    }

    @include min-screen(992px) {
      padding: 8px 0 !important;
    }
  }

  main.container {
    @include screen(576px, 991px) {
      padding: 0 24px;
    }

    @include max-screen(991px) {
      max-width: none;
    }
  }

  .message-btn {
    margin-right: 8px;
    min-width: 92px;
    height: 26px;
    padding: 0;
    text-transform: uppercase;

    &__primary {
      color: #0fbdd8;
      border-color: #0fbdd8;

      &:hover {
        background: #0fbdd8;
        color: #fff;
      }
    }

    &__secondary {
      color: #8e90a0;
      background: #8e90a0;

      &:hover {
        background: #8e90a0;
        color: #fff;
      }
    }
  }

  .video-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .video-box {
    max-width: 864px;
    border: solid 1px #e1e1e1;

    &__player {
      width: 100%;
      max-width: 864px;
      max-height: 484px;
    }
  }

  .more-info {
    width: 100%;
    min-height: 52px;
    display: flex;
    justify-content: center;
    align-items: center;
    /* Default (shiny) background-image: linear-gradient(255deg, #08aeea, #2af598); */
    background-image: linear-gradient(255deg, #08aeea, #01D4B9);

    &__link {
      font-family: "Lato", sans-serif;
      font-size: 12px;
      letter-spacing: 1.3px;
      text-align: center;
      color: #fff;
      text-transform: uppercase;

      &:hover {
        text-decoration: none;
        color: #fff;
      }
    }
  }

  .video-info {
    padding: 16px 32px;

    &__container {
      display: flex;
      justify-content: space-between;
      padding-bottom: 32px;
      align-items: center;
      border-bottom: solid 1px #dfdfdf;

      @include max-screen($sm - 1) {
        flex-direction: column;
        align-items: flex-end;
      }
    }

    &__main {
      width: 100%;
      display: flex;
      align-items: center;

      @include max-screen($sm - 1) {
        align-items: flex-start;
      }
    }

    &__avatar {
      width: 48px;
      height: 48px;
      margin-right: 20px;
    }

    &__title {
      font-family: "Open Sans", sans-serif;
      font-size: 24px;
      font-weight: 600;
      letter-spacing: -0.1px;
      color: #000;
    }

    &__publisher {
      margin: 0;
      font-family: "Open Sans", sans-serif;
      font-size: 12px;
      letter-spacing: 0.5px;
      color: #4f4f4f;

      span {
        font-weight: bold;
      }
    }

    &__views {
      min-width: 132px;
      font-family: "Open Sans", sans-serif;
      font-size: 20px;
      font-weight: 600;
      letter-spacing: 0.6px;
      color: #000;
    }
  }

  .video-actions {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding-top: 16px;

    > div {
      margin-bottom: 8px;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
    }

    &__right .video-action {
      margin: 0;
    }

    @include max-screen(450px) {
      &__left .video-action {
        margin-bottom: 8px;
      }
    }
  }

  .video-action {
    margin-right: 8px;
    padding: 0 8px;
    display: flex;
    align-items: center;
    font-family: "Open Sans", sans-serif;
    font-size: 16px;
    font-weight: 300;
    letter-spacing: 0.1px;
    color: #8e90a0;
    border-radius: 100px;
    border: solid 1px #c8c8c8;
    cursor: pointer;

    &__icon {
      margin-right: 8px;
    }

    span {
      margin-right: 4px;
    }
  }

  .comments-container {
    width: 100%;
    max-width: 864px;
    margin-top: 56px;
  }

  .comments-header__count {
    padding: 0 4px;
  }

  .comments-list {
    margin: 40px 0 88px 0;
    display: flex;
    flex-direction: column;
  }

  .comment-item {
    margin-bottom: 44px;
    display: flex;
    align-items: flex-start;

    &__avatar {
      margin-right: 16px;
      width: 48px;
      height: 48px;
    }
  }

  .comment {
    font-family: "Open Sans", sans-serif;

    &__header {
      margin-bottom: 4px;
      display: flex;
      align-items: center;
      letter-spacing: 0.5px;
    }

    &__author {
      margin: 0;
      font-weight: 600;
      color: #4f4f4f;
      font-size: 12px;
      text-transform: uppercase;
    }

    &__time {
      margin: 0;
      font-size: 12px;
      margin-left: 12px;
      color: #8e90a0;
    }

    &__content {
      font-size: 16px;
      color: #000;
    }

    &__actions {
      display: flex;
      flex-direction: row;
      align-items: center;

      .like-count {
        margin-left: 8px;
        font-size: 16px;
        letter-spacing: 0.5px;
        color: #8e90a0;
      }
    }

    &__likes {
      margin-left: 16px;
      margin-bottom: 8px;
    }
  }

  .comment-new {
    margin-top: 24px;
    display: flex;
    align-items: flex-start;

    &__avatar {
      width: 40px;
      height: 40px;
      margin-right: 8px;
    }

    &__textarea {
      resize: none;
    }
  }

  .related-videos {
    border: solid 1px #e1e1e1;
    width: 100%;
    padding: 24px 20px;

    &__header {
      padding: 0 4px;
      margin-bottom: 24px;
    }

    &__title {
      margin: 0;
      font-family: "Open Sans", sans-serif;
      font-size: 20px;
      font-weight: 300;
      color: #000;
    }

    &__list {
      display: flex;
      flex-direction: column;
    }

    &__footer {
      text-align: center;
    }
  }

  .related-video {
    margin-bottom: 16px;
    display: flex;

    &__img {
      width: 150px;
      height: 104px;
      margin-right: 8px;
    }

    &__author {
      margin-bottom: 4px;
      font-family: "Open Sans", sans-serif;
      font-size: 12px;
      letter-spacing: 0.2px;
      color: #8b8d92;
    }

    &__text {
      font-family: "Open Sans", sans-serif;
      font-size: 14px;
      font-weight: 600;
      letter-spacing: -0.3px;
      color: #20262b;
    }
  }

  .load-more {
    font-family: "Open Sans", sans-serif;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 1px;
    color: #4f4f4f;
    text-transform: uppercase;
  }
}

.team-page {
  .jumbotron {
    min-height: 516px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: linear-gradient(211deg, #08aeea, #01D4B9);
    background-size: cover;
    background-position: center;

    .title {
      margin-bottom: 20px;
      font-family: "Open Sans", sans-serif;
      font-size: 54px;
      font-weight: bold;
      line-height: 1.19;
      letter-spacing: -2.2px;
      text-align: center;
      color: #fff;
    }

    .subtitle {
      max-width: 800px;
      font-family: "Open Sans", sans-serif;
      font-weight: 300;
      font-size: 24px;
      text-align: center;
      color: #ffffff;
    }
  }

  .content {
    margin-top: -100px;
  }

  .card {
    border: none;
    min-width: 220px;
    padding: 16px;
    background: transparent;

    .card-img-top {
      margin: 0 auto;
      width: 218px;
      height: 218px;
      border-radius: 50%;
    }

    .card-body {
      padding: 36px;
      text-align: center;
    }

    .card-title {
      font-family: "Open Sans", sans-serif;
      font-size: 24px;
      font-weight: 600;
      letter-spacing: 1.5px;
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-image: linear-gradient(235deg, #08aeea, #01D4B9);
    }

    .card-text {
      text-transform: uppercase;
      font-family: "Open Sans", sans-serif;
      font-size: 16px;
      font-weight: bold;
      letter-spacing: 2.9px;
      color: #a3a3a7;
    }
  }

  .action img {
    width: 27px;
    height: 22px;
  }

  .view-more {
    display: block;
    width: 272px;
    height: 54px;
    margin: 0 auto;
    margin-top: 100px;
    margin-bottom: 152px;
  }
}

.event-page {

  .event-header {
    margin-bottom: 40px;
    padding: 32px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: solid 1px #dcdcdc;

    &__img {
      max-width: 160px;
    }

    &__info {
      padding: 0 20px;
    }

    &__type {
      margin-bottom: 8px;
      font-family: "Open Sans", sans-serif;
      font-size: 15px;
      letter-spacing: 0.6px;
      text-align: left;
      color: #8b8d92;
    }

    &__title {
      font-family: "Open Sans", sans-serif;
      font-size: 32px;
      font-weight: 600;
      line-height: 1.19;
      text-align: left;
      color: #000000;
    }

    &__presented-by {
      font-family: "Open Sans", sans-serif;
      font-size: 15px;
      color: #8e90a0;

      span {
        color: #1ec8d1;
      }
    }

    .invite-btn {
      margin: 0;
      padding: 12px;
      min-width: 212px;
    }

    @include max-screen($xl - 1) {
      flex-direction: column;

      &__img {
        margin-bottom: 16px;
      }
    }
  }

  .event-time {
    margin-bottom: 20px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    font-family: "Open Sans", sans-serif;
    font-size: 28px;
    font-weight: bold;
    letter-spacing: 0.9px;
    color: #1ec8d1;

    &__icon {
      margin-right: 20px;
      margin-top: 10px;
    }

    .event-action {
      margin: 0;
      margin-left: 32px;
    }
  }

  .event-location {
    margin-bottom: 32px;
    font-family: "Open Sans", sans-serif;
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 0.2px;
    color: #b4b5bf;
    text-align: center;

    &__icon {
      width: 20px;
      height: 28px;
      margin-right: 12px;
    }
  }

  .event-info {
    margin-bottom: 60px;

    &__title {
      margin-bottom: 12px;
      font-family: "Open Sans", sans-serif;
      font-size: 20px;
      font-weight: bold;
      letter-spacing: 0.6px;
      text-align: left;
      color: #000;
      text-transform: uppercase;
    }

    &__p {
      font-family: "Open Sans", sans-serif;
      font-size: 18px;
      line-height: 1.6;
      letter-spacing: 0.3px;
      text-align: left;
      color: #8e90a0;
    }
  }

  .invite-btn {
    display: block;
    margin: 0 auto;
    margin-bottom: 60px;
    min-width: 360px;
    padding: 16px;
  }

  .map {
    margin-bottom: 42px;
    width: 100%;

    img {
      width: 100%;
      max-height: 320px;
    }
  }

  .event-footer {
    padding: 20px 8px;
    margin-bottom: 80px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    border-top: 1px solid #dadada;

    &__views {
      display: flex;
      align-items: center;
    }

    &__actions {
      display: flex;

      @include max-screen($smd - 1) {
        flex-direction: column;
      }
    }
  }

  .event-views {
    margin: 0;
    font-family: "Open Sans", sans-serif;
    font-size: 15px;
    color: #8e90a0;

    &__img {
      margin-right: 16px;
    }
  }

  .event-action {
    margin-left: 12px;
    margin-bottom: 12px;
    padding: 8px;
    display: flex;
    align-items: center;
    font-family: "Lato", sans-serif;
    font-size: 13px;
    font-weight: bold;
    letter-spacing: 0.9px;
    color: #8e90a0;
    text-transform: uppercase;
    border: solid 1px #c8c8c8;
    border-radius: 100px;
    cursor: pointer;

    &__icon {
      margin-right: 12px;
    }

    &__item {
      margin: 0;
    }
  }
}

.channel-page {

  .channel-header {
    margin-bottom: 48px;
    padding: 48px 28px;
    display: flex;
    border: solid 1px #dcdcdc;

    @include max-screen(1000px) {
      flex-direction: column;
    }

    &__actions {
      display: flex;
    }

    &__info {
      width: 100%;
    }

    &__logo {
      min-width: 248px;
    }

    &__banner_wrap {

      &--mobile {
        margin: 16px 0 0;

        @include min-screen($smd) {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 16px;
        }

        @include min-screen($xl) {
          display: none;
        }

        #banner_below_video_player,
        #banner_below_video_player_house {
          margin-bottom: 0 !important;

          > [id^="google_ads_iframe_"] {
            margin-bottom: 8px;
          }
        }
      }

      &--desktop {
        @include max-screen($xl - 1) {
          display: none;
        }

        &.channel-header__banner_wrap--house {
          grid-area: 1 / 1;
          margin-right: 24px;
        }
      }
    }
  }


  main {
    margin-bottom: 120px;
  }

  .channel-action {
    cursor: pointer;
    display: flex;
    padding: 8px;
    max-height: 40px;
    min-width: 104px;
    align-items: center;
    margin-right: 16px;
    border-radius: 100px;
    border: solid 1px #c8c8c8;

    &:last-of-type {
      margin-right: 0;
    }

    &__icon {
      margin-right: 16px;
    }

    &__item {
      margin: 0;
    }
  }

  .channel-info__title {
    margin-bottom: 12px;
    font-family: "Open Sans", sans-serif;
    font-size: 32px;
    font-weight: 600;
    color: #000;
  }

  .channel-info__link {
    font-family: "Open Sans", sans-serif;
    font-size: 15px;
    letter-spacing: 0.3px;
    color: #1ec8d1;
    text-transform: lowercase;
  }

  .channel-info__views {
    display: inline-block;
    font-family: "Open Sans", sans-serif;
    font-size: 15px;
    color: #8e90a0;
  }

  .channel-info__items {
    .item {
      margin-right: 16px;
    }
  }

  .follow-btn {
    padding: 4px 20px;
    text-transform: uppercase;
    font-size: 12px;
    color: #19d1c2;
    border-color: #19d1c2;

    &:hover {
      border: none;
      background-color: #19d1c2;
    }
  }

  .channel-videos__title {
    margin-bottom: 20px;
    font-family: "Open Sans", sans-serif;
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 0.1px;
    color: #000;
  }

  .video-card {
    border: none;
    margin-bottom: 40px;

    &__img {
      width: 100%;
      margin-bottom: 8px;
    }

    &__author {
      font-family: "Open Sans", sans-serif;
      font-size: 15px;
      letter-spacing: 0.6px;
      color: #8b8d92;
      text-transform: uppercase;
    }

    &__title {
      font-family: "Open Sans", sans-serif;
      font-size: 18px;
      font-weight: 600;
      line-height: 1.39;
      letter-spacing: 0.1px;
      color: #20262b;
    }

    &__info {
      font-family: "Open Sans", sans-serif;
      font-size: 14px;
      letter-spacing: 0.1px;
      color: #a4a4a4;
    }
  }

  .video-card-container {
    padding: 4px 8px;
  }

  .filters__title {
    margin-bottom: 20px;
    font-family: "Open Sans", sans-serif;
    font-size: 20px;
    font-weight: 300;
    color: #000;
  }

  .filter-list {
    padding: 0;
    list-style: none;

    .filter-item {
      border-bottom: solid 1px #e7e7e7;
      position: relative;

      img {
        position: absolute;
        right: 0;
        top: 20px;
      }

      &__link {
        font-family: "Open Sans", sans-serif;
        font-size: 18px;
        line-height: 2.78;
        color: #000;
        text-decoration: none;
      }

      &:last-of-type {
        border: none;
      }
    }
  }

  .sub-filter-list {
    padding: 0;
    list-style: none;

    .sub-filter-item {
      margin-bottom: 8px;

      &__link {
        font-family: "Open Sans", sans-serif;
        font-size: 14px;
        letter-spacing: 0.1px;
        color: #95969b;
        text-decoration: none;

        &:hover {
          color: #95969b;
          text-decoration: none;
        }
      }
    }
  }

  .load-more {
    display: block;
    margin: 0 auto;
  }

  &.lecture-series-detail-page {
    .channel-header {
      padding: 16px;

      @include min-screen($xl) {
        padding: 48px 28px;
      }
    }
  }
}

.channel-detail-page {

  .navbar {
    @include screen(576px, 991px) {
      padding: 8px 24px;
    }

    @include min-screen(992px) {
      padding: 8px 0 !important;
    }
  }

  main.container {
    @include screen(576px, 991px) {
      padding: 0 24px;
    }

    @include max-screen(991px) {
      max-width: none;
    }
  }

  .channel-header {
    padding: 16px;

    @include min-screen(992px) {
      margin: 0 -15px 48px -15px;
    }

    @include min-screen(1200px) {
      padding: 48px 28px;
    }
  }

  .nav-tabs {
    @include min-screen(992px) {
      margin: 0 -15px;
    }
  }
}

.lecture-series-item-detail-page {

  .navbar {
    @include screen(576px, 991px) {
      padding: 8px 24px;
    }

    @include min-screen(992px) {
      padding: 8px 0 !important;
    }
  }

  main.container {
    @include screen(576px, 991px) {
      padding: 0 24px;
    }

    @include max-screen(991px) {
      max-width: none;
    }
  }

  .channel-header {
    padding: 16px;

    @include min-screen(992px) {
      margin: 0 -15px 48px -15px;
    }

    @include min-screen(1200px) {
      padding: 48px 28px;
    }
  }

  .nav-tabs {
    @include min-screen(992px) {
      margin: 0 -15px;
    }
  }
}

.webinar-page {

  .event-header {
    margin-bottom: 40px;
    padding: 32px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: solid 1px #dcdcdc;

    &__img {
      max-width: 160px;
    }

    &__info {
      padding: 0 20px;
    }

    &__type {
      margin-bottom: 8px;
      font-family: "Open Sans", sans-serif;
      font-size: 15px;
      letter-spacing: 0.6px;
      text-align: left;
      color: #8b8d92;
    }

    &__title {
      font-family: "Open Sans", sans-serif;
      font-size: 32px;
      font-weight: 600;
      line-height: 1.19;
      text-align: left;
      color: #000000;
    }

    &__presented-by {
      font-family: "Open Sans", sans-serif;
      font-size: 15px;
      color: #8e90a0;

      span {
        color: #1ec8d1;
      }
    }

    .rsvp-btn {
      margin: 0;
      padding: 12px;
      min-width: 260px;
    }

    @include max-screen($xl - 1) {
      flex-direction: column;

      &__img {
        margin-bottom: 16px;
      }
    }
  }

  .program__title {
    margin-bottom: 36px;
    font-family: "Open Sans", sans-serif;
    font-size: 26px;
    color: #000;
  }

  .program-item {
    display: flex;
    align-items: flex-start;
    margin-bottom: 40px;

    &__avatar {
      width: 112px;
      height: 112px;
      margin-right: 32px;
    }
  }

  .program-info__title {
    margin-bottom: 20px;
    font-family: "Open Sans", sans-serif;
    font-size: 16px;
    letter-spacing: 0.5px;
    text-align: left;
    color: #000;
  }

  .program-info__lecturer {
    margin-bottom: 4px;
    font-family: "Open Sans", sans-serif;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0.7px;
    text-align: left;
    color: #000;
  }

  .program-info__institute {
    font-family: "Open Sans", sans-serif;
    font-size: 12px;
    letter-spacing: 0.5px;
    text-align: left;
    color: #8b8d92;
  }

  .event-time {
    margin-bottom: 56px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    font-family: "Open Sans", sans-serif;
    font-size: 28px;
    font-weight: bold;
    letter-spacing: 0.9px;
    color: #1ec8d1;

    &__icon {
      margin-right: 20px;
      margin-top: 10px;
    }

    .event-action {
      margin: 0;
      margin-left: 32px;
    }
  }

  .event-info {
    margin-bottom: 60px;

    &__title {
      margin-bottom: 12px;
      font-family: "Open Sans", sans-serif;
      font-size: 18px;
      font-weight: bold;
      letter-spacing: 0.6px;
      text-align: left;
      color: #000;
      text-transform: uppercase;
    }

    &__p {
      font-family: "Open Sans", sans-serif;
      font-size: 20px;
      line-height: 1.6;
      letter-spacing: 0.3px;
      text-align: left;
      color: #8e90a0;
    }
  }

  .rsvp-btn {
    display: block;
    margin: 0 auto;
    margin-bottom: 60px;
    min-width: 360px;
    padding: 16px;
  }

  .event-footer {
    padding: 20px 8px;
    margin-bottom: 80px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    border-top: 1px solid #dadada;

    &__views {
      display: flex;
      align-items: center;
    }

    &__actions {
      display: flex;

      @include max-screen($smd - 1) {
        flex-direction: column;
      }
    }
  }

  .event-views {
    margin: 0;
    font-family: "Open Sans", sans-serif;
    font-size: 15px;
    color: #8e90a0;

    &__img {
      margin-right: 16px;
    }
  }

  .event-action {
    margin-left: 12px;
    margin-bottom: 12px;
    padding: 8px;
    display: flex;
    align-items: center;
    font-family: "Lato", sans-serif;
    font-size: 13px;
    font-weight: bold;
    letter-spacing: 0.9px;
    color: #8e90a0;
    text-transform: uppercase;
    border: solid 1px #c8c8c8;
    border-radius: 100px;
    cursor: pointer;

    &__icon {
      margin-right: 12px;
    }

    &__item {
      margin: 0;
    }
  }

  .related-content__title {
    margin-bottom: 20px;
    padding-left: 16px;
    font-family: "Open Sans", sans-serif;
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 0.1px;
    text-align: left;
    color: #000;
  }

  .related-content__items {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .content-item {
    width: 300px;
    margin: 8px;
    margin-bottom: 42px;

    &__img-container {
      position: relative;
    }

    &__img {
      width: 300px;
    }

    &__time {
      position: absolute;
      right: 8px;
      bottom: 8px;
      padding: 0 5px;
      opacity: 0.65;
      border-radius: 100px;
      background-color: #050505;
      font-family: "Open Sans", sans-serif;
      font-size: 14px;
      font-weight: bold;
      text-align: center;
      color: #fff;
    }
  }

  .content-info {
    padding: 8px;

    &__author {
      margin-bottom: 8px;
      font-family: "Open Sans", sans-serif;
      font-size: 15px;
      letter-spacing: 0.6px;
      text-align: left;
      color: #8b8d92;
    }

    &__title {
      margin-bottom: 8px;
      font-family: "Open Sans", sans-serif;
      font-size: 18px;
      font-weight: 600;
      line-height: 1.39;
      letter-spacing: 0.1px;
      text-align: left;
      color: #20262b;
    }

    &__date {
      font-family: "Open Sans", sans-serif;
      font-size: 14px;
      letter-spacing: 0.1px;
      text-align: left;
      color: #a4a4a4;
    }
  }
}

.heart-beat-page {

  .question {
    margin-bottom: 62px;

    &__title {
      margin-bottom: 20px;
      font-family: "Open Sans", sans-serif;
      font-size: 20px;
      font-weight: bold;
      letter-spacing: 0.1px;
      color: #000;

      img {
        margin-right: 12px;
        width: 26px;
        height: 29px;
      }
    }

    &__textarea {
      resize: none;
      padding: 12px 20px;
      width: 100%;
      border: solid 1px #dcdcdc;
    }
  }

  .news-feed__title {
    margin-bottom: 24px;
    font-family: "Open Sans", sans-serif;
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 0.1px;
    color: #000;
  }

  .actions {
    margin-bottom: 36px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .action {
    padding: 16px 42px;
    margin-bottom: 8px;
    width: 300px;
    height: 168px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    flex-wrap: nowrap;
    border: 1px solid #dcdcdc;

    &:hover {
      text-decoration: none;
    }

    &__label {
      margin: 0;
      font-family: "Lato", sans-serif;
      font-size: 18px;
      font-weight: 900;
      letter-spacing: 0.8px;
      text-align: center;
      color: #000;
      text-transform: uppercase;
    }

    &__description {
      margin: 0;
      font-family: "Lato", sans-serif;
      font-size: 12px;
      font-weight: 500;
      line-height: 1.25;
      letter-spacing: 1px;
      text-align: center;
      color: #8e98a5;
      width: 100%;
    }

    &--alternative {
      border: none;
      background-image: linear-gradient(208deg, #08aeea, #01D4B9);

      .action__label {
        line-height: 3.56;
        color: #fff;
      }

      .action__icon {
        margin-bottom: 20px;
      }
    }
  }

  .user-info {
    margin: 0 auto;
    margin-bottom: 40px;
    max-width: 300px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    &__avatar {
      margin-right: 20px;
      width: 48px;
      height: 48px;
    }

    &__name {
      margin: 0;
      font-family: "Lato", sans-serif;
      font-size: 23px;
      font-weight: 500;
      color: #000;
    }

    &__credits {
      margin: 0;
      font-family: "Lato", sans-serif;
      font-size: 15px;
      font-weight: 900;
      letter-spacing: 1px;
      color: #1ec8d1;
    }
  }

  .webinars {
    display: flex;
    flex-direction: column;
    align-items: center;

    &__title {
      min-width: 300px;
      margin-bottom: 32px;
      font-family: "Open Sans", sans-serif;
      font-size: 20px;
      font-weight: 300;
      text-align: left;
      color: #000;
    }
  }

  .webinar {
    max-width: 300px;
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: solid 1px #e7e7e7;

    &:last-of-type {
      border-bottom: none;
    }

    &__title {
      font-family: "Open Sans", sans-serif;
      font-size: 14px;
      font-weight: 600;
      letter-spacing: 0.1px;
      text-align: left;
      color: #1ec8d1;
    }

    &__location {
      margin: 0;
      font-family: "Open Sans", sans-serif;
      font-size: 12px;
      font-weight: bold;
      letter-spacing: 1.1px;
      text-align: left;
      color: #bebebe;
      text-transform: uppercase;

      img {
        margin-right: 8px;
      }
    }
  }

  .webinar-header {
    display: flex;
    justify-content: space-between;

    &__date,
    &__time {
      font-family: "Open Sans", sans-serif;
      font-size: 17px;
      letter-spacing: 0.9px;
      text-align: left;
      color: #000;

      span {
        font-weight: 600;
      }
    }
  }

  .load-more {
    display: block;
    margin: 0 auto;
    margin-bottom: 32px;
  }
}

.search-page {

  .search-term {
    @include min-screen(992px) {
      display: none;
    }
  }

  .filters {
    width: 100%;

    &--sticky {
      padding: 24px 0 0;

      @include min-screen(992px) {
        position: sticky;
        top: 23px;
        padding: 0;
      }
    }

    &__results_label {
      margin: 0;
      font-family: "Open Sans";
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 0.12px;
      color: #717175;
    }

    &__search_term {
      margin: 8px 0 0;
      font-family: "Open Sans";
      font-size: 20px;
      line-height: 26px;
      font-weight: 600;
      letter-spacing: -0.2px;
      color: #2c2c2e;
    }

    &__clear_filter_btn {
      display: inline-flex;
      align-items: center;
      flex-wrap: nowrap;
      width: 100%;
      font-size: 14px;
      line-height: 20px;
      font-weight: bold;
      letter-spacing: 0.07px;
      color: #e51934;
      white-space: nowrap;

      &:hover {
        text-decoration: none !important;
      }

      i {
        font-size: 16px;
        margin: 0 4px 0 0;
      }

      span {
        color: #717175;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    &__divider {
      border-top: 1px solid #E8E8E8;
      margin: 16px 0;
    }

    &__title {
      margin: 0 0 12px;
      font-size: 12px;
      line-height: 16px;
      font-weight: 400;
      letter-spacing: 0.12px;
    }

    &__inner_wrap {
      @include min-screen(992px) {
        height: calc(53vh - var(--search-term-height) + var(--page-scroll-amount));
        max-height: calc(100vh - var(--search-term-height) - 70px);
        overflow: auto;

        @media screen and (min-height: 1000px) {
          height: calc(68vh - var(--search-term-height) + var(--page-scroll-amount));
        }

        @media screen and (min-height: 1200px) {
          height: calc(73vh - var(--search-term-height) + var(--page-scroll-amount));
        }

        &::-webkit-scrollbar {
          width: 4px;
          height: 6px;
        }

        &::-webkit-scrollbar-track {
          background: rgba(#c7c7cc,0.3);
        }

        &::-webkit-scrollbar-thumb {
          border-radius: 10px;
          background: #c7c7cc;
        }

        &::-webkit-scrollbar-thumb:hover {
          background: #c7c7cc;
        }

        &::-webkit-scrollbar-thumb:active {
          background: #c7c7cc;
        }
      }
    }

    &__list {
      width: 100%;
      padding: 0 5px 0 0;
      display: flex;
      flex-direction: column;
      row-gap: 12px;
    }

    &__item {
      width: 100%;
      display: flex;
      justify-content: space-between;
      font-family: "Open Sans", sans-serif;
      font-size: 14px;
      line-height: 20px;
      font-weight: 600;
      margin: 0;
      letter-spacing: 0.1px;
      text-align: left;
      color: #000;

      &:hover {
        text-decoration: none;
      }
    }

    &__cnt {
      padding: 0 12px;
      font-family: "Open Sans", sans-serif;
      font-size: 14px;
      font-weight: 600;
      text-align: left;
      color: #a7a7a7;
      border-radius: 15.5px;
      border: solid 1px #d4d4d4;
    }
  }

  .news-feed__title {
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;
    font-family: "Open Sans", sans-serif;
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 0.1px;
    color: #000;
  }

  .sort-by {
    display: flex;
    align-items: center;
    font-family: "Lato", sans-serif;
    font-size: 15px;
    font-weight: bold;
    letter-spacing: 1px;
    text-align: left;
    color: #8e90a0;
    text-transform: uppercase;

    .form-control {
      margin-left: 16px;
      width: unset;
    }
  }

  .load-more {
    display: block;
    margin: 0 auto;
    margin-bottom: 32px;
  }
}

.settings-page {
  .filters {
    width: 100%;
    padding-top: 24px;

    &__container {
      display: flex;
      flex-direction: column;
    }

    &__title {
      margin-bottom: 20px;
      font-family: "Open Sans", sans-serif;
      font-size: 20px;
      font-weight: 300;
      color: #000;
    }

    &__list {
      padding-bottom: 24px;
      width: 100%;
      display: flex;
      flex-direction: column;
    }

    &__item {
      width: 100%;
      font-family: "Open Sans", sans-serif;
      font-size: 14px;
      font-weight: 600;
      margin-top: 0.8em;
      margin-bottom: 0.8em;
      letter-spacing: 0.1px;
      text-align: left;
      color: #000;

      &:hover {
        text-decoration: none;
      }

      &.active {
        text-decoration: underline;
      }
    }
  }

  .settings-tab {
    &__options-group-title {
      font-family: $fontQuaternary;
      font-weight: 500;
      font-size: 18px;
      line-height: 24px;
      color: #000;
      margin-bottom: 4px;
    }

    &__options-group {
      margin: 0 -12px;

      @include min-screen($md) {
        max-width: 50%;

        &:has(.vm-checkbox .vm-label) {
          max-width: 60%;
        }
      }

      .vm-radiobox,
      .vm-checkbox {
        label {
          padding: 10px 12px;

          span {
            padding-top: 1px;
          }

          .vm-label {
            margin-top: 1px;
          }
        }
      }

      .vm-checkbox {
        &__extra-icon {
          display: block;
          margin: 1px 0 0;

          @include min-screen($md) {
            display: none;
          }
        }

        .vm-label {
          display: none;

          @include min-screen($md) {
            display: block;
          }
        }
      }
    }

    &__buttons-wrap {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 24px 0 0;
      border-top: 1px solid rgba(102, 109, 110, 0.19);
      margin: 24px 0 0;

      @include min-screen($md) {
        margin: 24px -12px 0;
      }
    }

  }
}

.navbar {
  padding: 1rem;

  .navbar-nav {
    .nav-item {
      position: relative;
      font-family: "Lato", sans-serif;
      font-weight: 700;
      font-size: 14px;
      letter-spacing: 0.9px;
      color: #a5a7a9;
      text-transform: uppercase;
    }
  }
}

.user__avatar {
  margin-right: 12px;
  width: 40px;
  height: 40px;
}

.user__name {
  text-transform: none;
}

.form-control {
  font-family: "Open Sans", sans-serif;
  color: #a3a3a7;
  border-radius: 3px;
  border: solid 1px rgba(151, 151, 151, 0.3);
}

.form-container {
  display: flex;
  justify-content: flex-end;

  .navigation-form {
    display: flex;
    align-items: center;

    .form-group {
      position: relative;
      margin: 0 8px;
    }

    .forgot-password {
      right: 0;
      margin: 0;
    }

    .btn {
      margin-top: 8px; /* FIXME: Hackish solution for proper Forget password display on mobile view. Does not display correctly search form button on mobile view (overriden). */
    }

    @include max-screen($md - 1) {
      .form-container {
        margin-bottom: 16px;
      }
    }

    @include max-screen($sm) {
      .form-container {
        justify-content: center;

        .navigation-form {
          width: 100%;
          flex-direction: column;

          .form-group {
            width: 90%;
            margin: 12px 0;
          }
        }
      }
    }
  }
}

.btn-call {
  cursor: pointer;
  min-width: 134px;
  border-radius: 100px;
  font-family: "Lato", sans-serif;
  font-size: 14px;
  letter-spacing: 0.9px;
  text-transform: uppercase;

  &-primary {
    color: #fff;
    border: none;
    background-image: linear-gradient(216deg, #08aeea, #01D4B9);
  }

  &-outline-primary {
    background-color: transparent;
    color: #fff;
    border-color: #fff;

    &:hover {
      border-color: #fff;
      background-image: linear-gradient(216deg, #08aeea, #01D4B9);
    }
  }
}

.content {
  margin-top: 100px;

  .content-heading {
    margin-bottom: 50px;
    font-family: "Open Sans", sans-serif;
    font-size: 42px;
    font-weight: 300;
    line-height: 1.1;
    letter-spacing: 0.1px;
    text-align: center;
    color: #000;

    &:after {
      content: '';
      display: block;
      margin: 0 auto;
      position: relative;
      top: 20px;
      width: 47px;
      height: 4px;
      background-image: linear-gradient(251deg, #08aeea, #01D4B9);
    }
  }
}

.section {
  margin-bottom: 80px;

  .section-header {
    margin-bottom: 25px;
    font-family: "Open Sans", sans-serif;
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0.9px;
    color: #000;
    text-transform: uppercase;

    &:before {
      content: '';
      display: inline-block;
      width: 45px;
      height: 23px;
      margin-right: 20px;
      background-image: url("//static.vumedi.com/gfx/totem.svg");
    }
  }

  .section-item {
    border: none;

    &__img {
      margin-bottom: 12px;
    }

    &__author {
      margin-bottom: 4px;
      font-family: "Open Sans", sans-serif;
      font-size: 15px;
      letter-spacing: 0.2px;
      text-align: left;
      color: #8b8d92;
    }

    &__title {
      font-family: "Open Sans", sans-serif;
      font-size: 20px;
      font-weight: 600;
      line-height: 1.5;
      letter-spacing: 0.1px;
      text-align: left;
      color: #20262b;
    }
  }
}

.create-account-btn {
  display: block;
  width: 100%;
  max-width: 250px;
  height: 50px;
}

.video-item {
  margin-bottom: 32px;
  width: 100%;
  padding: 8px;
  display: flex;

  &.featured {
    background-color: #fffeee;
  }

  @include max-screen($smd) {
    flex-direction: column;

    .video-item__img-container {
      margin: 0;
      margin-bottom: 16px;
    }
  }

  &__img-container {
    position: relative;
    margin-right: 40px;
    max-width: 300px;
  }

  &__img {
    max-width: 300px;
  }

  &__length {
    position: absolute;
    right: 8px;
    bottom: 8px;
    padding: 0 5px;
    opacity: 0.65;
    border-radius: 100px;
    background-color: #050505;
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    font-weight: bold;
    text-align: center;
    color: #fff;
  }

  &__author {
    font-family: "Open Sans", sans-serif;
    font-size: 15px;
    letter-spacing: 0.6px;
    text-align: left;
    color: #8b8d92;
  }

  &__title {
    font-family: "Open Sans", sans-serif;
    font-size: 20px;
    font-weight: 600;
    line-height: 1.4;
    letter-spacing: 0.1px;
    text-align: left;
    color: #20262b;
  }

  &__desc {
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    letter-spacing: 0.1px;
    text-align: left;
    color: #a4a4a4;
  }
}
