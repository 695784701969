.vm-checkbox {
    display: block;
    width: 100%;

    input {
        visibility: hidden;
        display: block;
        width: 0;
        height: 0;
    }

    label {
        position: relative;

        display: flex;
        align-items: flex-start;
        gap: 8px;
        flex: 1 0 0;
        margin: 0;
        padding: 6px 12px;

        cursor: pointer;

        @include text-small-medium;
        color: get-color(button, tertiary, text, default);

        background: transparent;
        border-radius: 12px;

        &:hover,
        &:active {
            background: get-color(button, background, tertiary, hover);
        }

        .icon {
            display: block;
            width: 20px;
            height: 20px;
            flex-shrink: 0;
            border-radius: 6px;
            background: get-color(bg, transparent, quaternary);
            text-align: center;
            line-height: 20px;
            color: transparent;
        }

        p {
            margin: 0 !important;
        }
    }

    input:checked + label .icon {
        background-color: get-color(button, primary, bg, default);
        color: get-color(text, inverse);
    }

    &__extra-icon {
        display: none;

        .icon {
            background-color: transparent !important;
        }
    }

    &--big {
        label {
            padding: 12px;
            font-size: 16px;
            line-height: 24px;

            .icon {
                width: 24px;
                height: 24px;
                line-height: 24px;
            }
        }
    }
}
