.vm-label {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    box-sizing: border-box;
    height: 20px;
    padding: 3px 8px;
    border-radius: 9999px;
    font-family: $fontQuaternary;
    font-weight: 700;
    font-size: 11px;
    line-height: 14px;
    letter-spacing: 0.55px;
    text-transform: uppercase;

    &--default {
        background-color: get-color(bg, transparent, tertiary);
        color: get-color(text, primary);
    }

    &--info {
        background-color: get-color(status, info, bg);
        color: get-color(status, info, text-primary);
    }

    &--warning {
        background-color: get-color(status, warning, bg);
        color: get-color(status, warning, text-secondary);
    }

    &--error-light {
        background-color: get-color(status, error, bg);
        color: get-color(status, error, text-secondary);
    }

    &--error {
        background-color: get-color(status, error, text-secondary);
        color: get-color(text, inverse);
    }
}
