.related_video_card {

  & + .related_video_card {
    margin: 16px 0 0;
  }

  &__link {
    display: flex;
    column-gap: 12px;
    text-decoration: none !important;
  }

  &__figure {
    position: relative;
    align-self: flex-start;
    flex-shrink: 0;
    width: 150px;
    margin: 0;
    overflow: hidden;
    border: 1px solid #666D6E30;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }

  &__duration {
    position: absolute;
    bottom: 8px;
    right: 8px;
    height: 20px;
    padding: 0 8px;
    border-radius: 9999px;
    background-color: #000000B2;
    font-family: $fontPrimary;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: calc(12px * 0.01);
    color: #fff;
  }

  &__tags_wrap {
    display: flex;
    flex-wrap: wrap;
    gap: 4px;

    &:not(:empty) {
      margin: 0 0 4px;

      @include screen(768px, 991px) {
        margin: 0 0 8px;
      }
    }

  }

  &__tag {
    display: -webkit-box;
    flex-wrap: nowrap;
    height: 20px;
    padding: 0 8px;
    border-radius: 9999px;
    margin: 0;
    background-color: #9ED7FF52;
    font-family: $fontPrimary;
    font-size: 11px;
    line-height: 20px;
    font-weight: 700;
    letter-spacing: calc(12px * 0.05);
    color: #368;
    -webkit-line-clamp: 1;
    line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    text-transform: uppercase;
  }

  &__title {
    display: -webkit-box;
    margin: 0;
    font-family: $fontPrimary;
    font-size: 14px;
    line-height: 20px;
    font-weight: 550;
    letter-spacing: calc(12px * 0.005);
    color: #18191a;
    -webkit-line-clamp: 3;
    line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__contributors,
  &__author,
  &__metadata {
    margin: 4px 0 0;
    font-family: $fontPrimary;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: calc(12px * 0.01);
    color: #666d6e;

    @include screen(768px, 991px) {
      margin: 8px 0 0;
    }
  }

  &__contributors {
    color: #18191a;
    font-weight: 600;
  }

  &__metadata {
    display: flex;
    column-gap: 4px;

    p {
      margin: 0;
    }
  }

  &__metadata_separator {
    font-size: 20px;
  }

}
